$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {

    };

    $('.image-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        slidesToShow: 1,
        lazyLoad: 'progressive',
        autoplay: true,
        speed: 2000,
        fade:true,
        dots: false,
        pauseOnHover:false,
        arrows: false
    });


    $('.teaser-slider').slick({
      autoplay: true,
        dots: false,
        arrows:true,
        infinite: false,
        speed: 300,
        slidesToScroll:3,
        slidesToShow: 3,
        dots:false,
        responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToScroll:2,
            slidesToShow: 2,
            dots:true,
          }
        },
        {
          breakpoint: 640,
          settings: {
            dots:true,
            slidesToScroll:1,
            slidesToShow: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
        ]
    });

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


    /**
     * PREVENTS CONTENT SCROLLING AFTER TOGGELING
     */
    // naviToggled.onchange('change');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/